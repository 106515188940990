<template>
  <div class="documents-tree">
    <ul class="list-group list-group-flush">
      <li class="list-group-item" :class="{ 'active': page.id === currentPage }" v-for="page in pages" :key="page.id" @click="openPage(page.id)">
        {{ page.title | truncate(25, '...') }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  NEW_PAGE,
  FETCH_USER_PAGES
} from '@/store/actions.type'

export default {
  created () {
    this.$store.dispatch(FETCH_USER_PAGES)
  },
  methods: {
    newPage () {
      this.$store.dispatch(NEW_PAGE)
    },
    openPage (id) {
      if (this.$route.params.id !== id) {
        this.$router.push('/d/' + id)
      }
    }
  },
  computed: {
    ...mapGetters(['pages', 'currentPage'])
  }
}
</script>

<style lang="scss">
.documents-tree {
  padding: 0 10px;
  li {
    cursor: pointer;
    transition: .15s all ease-in-out;
    background: transparent;
    border-radius: 8px !important;
    border: none;
    + li {
      margin-top: 5px !important;
    }
    &:hover {
      background: #f5f5f5;
    }
    &.active {
      color: #000;
      background: #dad9d9;
      &:hover {
        background: #f5f5f5;
      }
    }
  }
}
</style>
