<template>
  <div id="main-page" class="vh-100 d-flex flex-row">
    <div class="sidebar-container">
      <Sidebar />
    </div>
    <div class="main-frame">
      <router-view :key="$route.params.id"/>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'

export default {
  components: {
    Sidebar
  }
}
</script>

<style>
.sidebar-container {
  width: 15%;
}
.main-frame {
  width: 85%;
}
</style>
