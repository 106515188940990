<template>
  <div id="sidebar" class="vh-100 d-flex flex-column">
    <div class="modal fade" id="userSettings" ref="settingsModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ user.auth.displayName }}'s Settings</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent>
              <div class="row mb-3">
                <div class="col">
                  <label for="firstname" class="form-label">First Name</label>
                  <input v-model.trim="settings.firstname" type="text" placeholder="John" id="firstname" class="form-control"/>
                </div>
                <div class="col">
                  <label for="lastname" class="form-label">Last Name</label>
                  <input v-model.trim="settings.lastname" type="text" placeholder="Smith" id="lastname" class="form-control"/>
                </div>
              </div>
              <div class="mb-3">
                <label for="email2" class="form-label">Email</label>
                <input v-model.trim="settings.email" type="text" placeholder="john.smith@gmail.com" id="email2" class="form-control"/>
              </div>
              <div class="mb-3">
                <label for="password2" class="form-label">Password</label>
                <input v-model.trim="settings.password" type="password" placeholder="Really strong password!" id="password2" class="form-control"/>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary text-white" @click="updateUser">Save changes</button>
          </div>
        </div>
      </div>
    </div>
    <div class="dropdown user-profile-dropdown">
      <div class="user-profile p-3 d-flex dropdown-toggle" id="userProfile" data-bs-toggle="dropdown" aria-expanded="false" data-bs-offset="0,5">
        <div class="user-image d-flex align-items-center">
          <img :src="user.auth.photoURL">
        </div>
        <div class="user-details ms-2 flex-fill">
          <p class="mb-0"><b>{{ user.auth.displayName}}</b></p>
          <p class="text-muted mb-0"><small>{{ user.email }}</small></p>
        </div>
      </div>
      <ul class="dropdown-menu" aria-labelledby="userProfile">
        <li><button class="dropdown-item" type="button" data-bs-toggle="modal" data-bs-target="#userSettings">Settings</button></li>
        <li><hr class="dropdown-divider"></li>
        <li><button class="dropdown-item" type="button" @click="logout">Logout</button></li>
      </ul>
    </div>
    <hr class="my-0">
    <h5 class="p-3">My Pages</h5>
    <DocumentsTree />
    <div class="mt-auto position-static add-new-page-button" @click="newPage">
      <i class="bi-plus"></i> New page
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Modal } from 'bootstrap'

import DocumentsTree from '@/components/DocumentsTree.vue'

import {
  UPDATE_USER_PROFILE,
  UPDATE_USER_EMAIL,
  UPDATE_USER_PASSWORD,
  LOGOUT,
  NEW_PAGE
} from '@/store/actions.type'

export default {
  data () {
    return {
      settings: {
        firstname: '',
        lastname: '',
        email: '',
        password: ''
      },
      settingsModal: null
    }
  },
  components: {
    DocumentsTree
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    logout () {
      this.$store.dispatch(LOGOUT)
    },
    newPage () {
      this.$store.dispatch(NEW_PAGE)
    },
    updateUser () {
      if (this.settings.firstname !== this.user.firstname ||
      this.settings.lastname !== this.user.lastname) {
        this.$store.dispatch(UPDATE_USER_PROFILE, {
          firstname: this.settings.firstname,
          lastname: this.settings.lastname
        })
      }
      if (this.settings.email !== this.user.email) {
        this.$store.dispatch(UPDATE_USER_EMAIL, this.settings.email)
      }
      if (this.settings.password) {
        this.$store.dispatch(UPDATE_USER_PASSWORD, this.settings.password)
      }
      this.settingsModal.hide()
    }
  },
  created () {
    this.settings.firstname = this.user.firstname
    this.settings.lastname = this.user.lastname
    this.settings.email = this.user.email
  },
  mounted () {
    this.settingsModal = new Modal(this.$refs.settingsModal)
  }
}
</script>

<style lang="scss">
#sidebar {
  background: #ececec;
}
.user-profile {
  cursor: pointer;
  transition: .3s all ease-in-out;
}
.user-profile:hover {
  background: #e0e0e0;
}
.user-image {
  width: 40px;
}
.user-image img {
  width: 40px;
  height: 40px;
  border-radius: 2px;
}
.user-profile-dropdown .dropdown-menu {
  width: calc(100% - 20px);
  margin: 0 10px !important;
}
.user-profile-dropdown .dropdown-toggle:after {
  display: none;
}
.add-new-page-button {
  cursor: pointer;
  transition: .3s all ease-in-out;
  padding: 10px;
  &:hover {
    background: #e0e0e0;
  }
}
</style>
